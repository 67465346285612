import { css } from '@emotion/react';
import { useState, ChangeEvent } from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';

import { HeadInner } from '../../components/Head';
import { Layout } from '../../components/layout';
import { RegistrationLayout } from '../../components/forms/RegistrationLayout';
import { PostConversionInfo, handlePostWithTrackingCV } from '../../util/post';
import { colors } from '../../theme/colors';

export const Head = () => (
  <HeadInner
    path='/contact'
    title='お問い合わせ'
    description='paild（ペイルド）のお問い合わせページです。お気軽にご連絡ください'
  />
);

const ContactPage = () => {
  const [agree, setAgree] = useState(false);

  const handleAgree = () => {
    setAgree(!agree);
  };

  const location = useLocation();
  const env = process.env.CONTEXT;

  const portalId = process.env.HUBSPOT_ID ? process.env.HUBSPOT_ID : '';
  const formId =
    env === 'dev' || env === 'stg'
      ? 'e25bbda1-ba32-4d02-a597-6f1d17f6636f'
      : '40617f7f-da54-415b-b348-a9ff9d772b6e';

  const [form, setForm] = useState<PostConversionInfo>({
    email: '',
    lastname: '',
    firstname: '',
    company: '',
    employees: '',
    department: '',
    jobtitle: '',
    phone: '',
    industry: '',
    contact_purpose: '',
    contact: '',
    pageUri: location.href,
    registration: false,
    request_document: false,
    request_contact: true,
    service_codes: ['prepaid_card'],
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleTextareaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const requiredList = [
    'company',
    'email',
    'employees',
    'lastname',
    'firstname',
    'department',
    'phone',
    'industry',
    'contact_purpose',
    'contact',
  ];

  const onPostButtonClick = async () => {
    await handlePostWithTrackingCV(form, portalId, formId, document.title);
    navigate('/contact/complete/');
  };

  const links = [{ name: 'お問い合わせ', path: '/contact/' }];

  return (
    <Layout background='simple' isBottomMenu={false} links={links}>
      <RegistrationLayout
        form={form}
        onChange={handleChange}
        onTextareaChange={handleTextareaChange}
        onCheckBoxChange={handleCheck}
        onSelectChange={handleSelectChange}
        agree={agree}
        handleAgree={handleAgree}
        requiredList={requiredList}
        onPostButtonClick={onPostButtonClick}
      >
        <h2 css={RequestContactTitleStyle}>お問い合わせ</h2>
        <p>
          フォームに必要事項とご相談内容をご記入ください。弊社の担当より、お問い合わせ内容に応じてご連絡いたします。
          <br />
          営業目的でのお問い合わせはご遠慮ください。
        </p>
        <div css={AlreadyStyle}>
          <div css={{ width: '100%' }}>
            <h3>すでにpaildをご利用中のお客様へ</h3>
          </div>
          <div css={SectionStyle}>
            <a href='https://support.paild.jp/hc/ja'>
              <h4>よくある質問</h4>
            </a>
            <p>
              paildのよくある質問や機能の詳細情報をまとめています。ヘルプページをご覧いただき、解決されない場合は下記の管理者専用の問い合わせフォームよりご連絡ください。
            </p>
          </div>
          <div css={SectionStyle}>
            <a href='https://app.paild.jp/login'>
              <h4>
                管理者専用の問い合わせフォーム（ログイン画面へ遷移します）
              </h4>
            </a>
            <p>
              paildにログインのうえ、管理画面の左下にある「お問い合わせ」からご連絡いただくと、情報の確認や回答がスムーズに行えます。機能の不具合や決済に関するご質問、お客様情報の変更などは専用フォームよりお問い合わせください。
            </p>
          </div>
        </div>
      </RegistrationLayout>
    </Layout>
  );
};

const RequestContactTitleStyle = css({
  color: colors.black,
  fontSize: 40,

  '@media(max-width: 1119px)': {
    fontSize: 28,
  },
});

const SectionStyle = css({
  backgroundColor: '#f0f0f0',
  padding: '32px 16px',

  a: {
    color: colors.primary,
    textDecoration: 'underline',
  },

  h4: {
    fontSize: 20,
  },

  p: {
    marginTop: '20px',
  },
});

const AlreadyStyle = css({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 16,
});

export default ContactPage;
